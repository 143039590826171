h1 {
    font-size: 2em;
    margin-top: 0;
}
  
p {
    margin-bottom: 1em;
}

.container {
    font-family: sans-serif;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.rtl {
    direction: rtl;
    text-align: right;
}

.header {
    display: flex;
    align-items: center;
  }
  
.logo {
    width: 80px; /* Adjust as needed */
    height: auto;
    margin-right: 10px;
}

.badge {
    width: 250px;
    height: auto;
}

.header-content {
    flex: 1;
}

.app-title {
    margin: 0;
    padding-top: 20px;
}
  
.app-subtitle {
    margin-top: 5px;
    color: gray;
}

.main {
    margin: 80px 0;
}

.description {
    margin-top: 80px;
}

.container-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.photo-section {
    width: 100%;
    overflow: auto;
}
  
.photo-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.photo-list .small-screen-photo {
    width: 200px;
    height: auto;
    margin-right: 20px;
    object-fit: cover;
    display: none; /* Hide small-screen photos on small screens */
}

.photo-list .large-screen-photo {
    width: 400px;
    height: auto;
    margin-right: 20px;
    object-fit: cover;
    display: block; /* Display large-screen photos by default */
}
  
.photo-list li:last-child {
    margin-right: 0;
}
  
.photo-list img {
    border-radius: 8px;
    border: 1px solid lightgrey;
}

@media (max-width: 767px) {
    .photo-list .large-screen-photo {
        display: none; /* Hide large-screen photos on small screens */
    }
    
    .photo-list .small-screen-photo {
        display: block; /* Display small-screen photos on small screens */
    }
}